import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import BigButton from '../../components/Ui/BigButton'
import { StaticImage } from 'gatsby-plugin-image'
import getTicketURL from '../../utils/TicketURL'

const Hebergement = () => {
  const metaTitle = 'Hébergement | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, hébergement, hôtel, camping, dormir'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Hébergement"
      />
      <div className="container content pb-12">

        <iframe title="Vidéo hébergement" className="rounded-3xl" src="https://stay22.com/embed/63837bda1da5be0016e282fe" id="stay22-widget" width="100%" height="460" frameBorder="0"></iframe>

        <div>
          <h2>Groupe Facebook Le Camping citoyen du Festif!</h2>
          <p>Ce groupe sert à mettre en relation les festivalier.ère.s en quête d'hébergement (camping, motorisés) avec les citoyen.ne.s de Baie-St-Paul. On t’ invite à y publier tes offres et demandes.</p>
          <br/>
          <p>Merci de votre collaboration et bon Festif!</p>
          <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
            <p><BigButton href="https://www.facebook.com/groups/campingcitoyendufestif/" text="REJOINDRE LE GROUPE"/></p>
          </div>
        </div>

        <div>

          {/* Village Pronature à Maison Mère */}
          <div className="mt-12">
            <h2>Village Pronature à Maison Mère</h2>

              <h4>Adresse:</h4>
              <p>63 rue Ambroise-Fafard</p>

              <h4>Accès:</h4>
              <p>Le Camping est à 2 minutes à pied du cœur du festival.</p>
              <p>Le site est situé à l’arrière de Maison-Mère, au 63 rue Ambroise-Fafard (suivre les panneaux d'indication).</p>

              <h4>Stationnement:</h4>
              <p>Le stationnement pour vos véhicules ne sera pas directement sur le site du camping, mais plutôt à quelques minutes de marche hors du site (suivez les indications). Il y aura tout de même un débarcadère au camping pour y déposer son matériel.</p>

              <h4>Heures d'ouverture :</h4>
              <p>Les arrivées sont possibles à compter du mercredi le 17 juillet à 15h</p>
              <p>Du mercredi au samedi, les arrivées se font de 15:00 à 22:00.</p>
              <p>Les départs se font le dimanche 21 juillet avant midi.</p>

              <div className='ml-9'>
                <h3>VAN</h3>

                <h4>Passeport van (nuitées non disponibles à l’unité)</h4>
                <p>Le tarif donne accès aux nuits du mercredi 17, jeudi 18, vendredi 19 et samedi 20 juillet.</p>
                <p>Les nuits non utilisées ne sont pas transférables à une autre personne, car une fois le véhicule installé sur le site, il sera impossible de le déplacer avant votre départ définitif (à moins d’une urgence).</p>
                <br/>
                <p>Veuillez noter qu’il ne sera pas possible de choisir votre emplacement, ils seront attribués en ordre d’arrivée.</p>

                <h4>Ce qui est admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les vans/Westfalias et VR de 22 pieds et moins;</p></li>
                    <li><p>Un maximum de 4 personnes par terrain (et une seule van); </p></li>
                    <li><p>Les tentes de toit sur un véhicule;</p></li>
                    <li><p>Dormir dans tout véhicule ou motorisé, n’excédant pas 22 pieds ET n’étant pas attaché par un véhicule, sera accepté.</p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>les tentes-roulottes ou toute roulotte/habitation attachée par un véhicule;</p></li>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Les vans/Westfalias et VR de plus de 22 pieds;</p></li>
                    <li><p>Plus de une van sur le même site;</p></li>
                    <li><p>Une tente placée à côté de la van;</p></li>
                  </ul>
                </div>

                <h4>Détails</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 22 pieds x 10 pieds;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des roulottes de douches et des toilettes seront à la disposition des campeurs;</p></li>
                    <li><p>Des lavabos sont disponibles sur le site;</p></li>
                    <li><p>Tout au long de votre séjour et à votre départ, il est très important de laisser votre espace propre et de trier vos déchets de façon adéquate aux endroits prévus à cet effet; </p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Une fois le véhicule installé sur le site, il sera impossible de quitter avant votre départ définitif (à moins d’une urgence);</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate.</p></li>
                  </ul>
                </div>
              </div>

              <div className='ml-9'>
                <h3>TENTES</h3>

                <h4>Deux options s’offrent à vous: le Passeport OU les nuitées disponibles à l’unité.</h4>
                <p><span className='underline'>Passeport tente:</span> le tarif donne accès aux nuits du mercredi 17, jeudi 18, vendredi 19 et samedi 20 juillet.</p>
                <p><span className='underline'>Billets unitaires:</span> Veuillez respecter la nuitée associée à votre billet. Contrôle sur place.</p>

                <h4>Ce qui est admis</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Une tente par terrain</p></li>
                    <li><p>Les tentes de 10 pieds  x 10 pieds et moins (Les tentes conçues pour plus de 4 personnes sont donc hors dimensions) ;</p></li>
                    <li><p>Un maximum de 4 personnes par terrain (et une seule tente);</p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Les tentes de plus de 10 pieds x 10 pieds (donc conçue pour plus de 4 personnes);</p></li>
                    <li><p>Plus de une tente sur le même site, ou une tente cuisine (vous devez acheter un 2e emplacement);</p></li>
                  </ul>
                </div>

                <h4>Détails</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 10 pieds x 10 pieds;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des roulottes de douches et des toilettes seront à la disposition des campeurs;</p></li>
                    <li><p>Des lavabos sont disponibles sur le site;</p></li>
                    <li><p>Tout au long de votre séjour et à votre départ, il est très important de laisser votre espace propre et de trier vos déchets de façon adéquate aux endroits prévus à cet effet;</p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate;</p></li>
                  </ul>
                </div>
              </div>

              <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
                <p className="mt-6"><BigButton href={getTicketURL()} text="RÉSERVER"/></p>
              </div>
            </div>

            {/* CAMPING DU FLEUVE */}
            <div className="mt-12">
              <h2>Camping du Fleuve</h2>

              <div className='ml-9'>
                <h3>TENTES</h3>
                <h4>Deux options s’offrent à vous: le Passeport OU les nuitées disponibles à l’unité.</h4>
                <p><span className='underline'>Passeport tente:</span> le tarif donne accès aux nuits du mercredi 17, jeudi 18, vendredi 19 et samedi 20 juillet.</p>
                <p><span className='underline'>Billets unitaires:</span> Veuillez respecter la nuitée associée à votre billet. Contrôle sur place.</p>

                <h4>Heures d'ouverture</h4>
                <p>Les arrivées sont possibles à compter du mercredi le 17 juillet à 15h</p>
                <p>Du mercredi au samedi, les arrivées se font de 15:00 à 22:00.</p>
                <p>Les départs se font le dimanche 21 juillet avant midi.</p>

                <h4>Ce qui est admis</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les tentes de 10 pieds x 10 pieds et moins (Les tentes conçues pour plus de 4 personnes sont donc hors dimensions);</p></li>
                    <li><p>Un maximum de 4 personnes par terrain (et une seule tente); </p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Faire des feux (en raison de la proximité de terrains protégés);</p></li>
                    <li><p>Les tentes de plus de 10 pieds x 10 pieds (donc conçue pour plus de 4 personnes);</p></li>
                    <li><p>Plus de une tente sur le même site, ou une tente cuisine (vous devez acheter un 2e emplacement);</p></li>
                  </ul>
                </div>

                <h4>Détails:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 10 pieds x 10 pieds;</p></li>
                    <li><p>Les campeurs auront accès gratuitement à des douches au 63 rue Ambroise-Fafard, derrière les bâtiments de la Maison Mère. Il s'agit des blocs douche du camping Village Festif! de Maison Mère. Compter une dizaine de minutes de marche pour s'y rendre par le Chemin des Petites-Franciscaines;</p></li>
                    <li><p><a href="https://www.ouiouicafebuvette.com/charlevoix" target='_blank' rel="noreferrer">Oui Oui Buvette Forestière</a> sera sur place de 9h à midi les 18-19-20 juillet pour offrir des options de déjeuner. Consulte le <a href="/infos/menu#info_content">menu</a>;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des toilettes seront à la disposition des campeurs;</p></li>
                    <li><p>Un lavabo sera disponible sur le site;</p></li>
                    <li><p>Tout au long de votre séjour et à votre départ, il est très important de laisser votre espace propre et de trier vos déchets de façon adéquate aux endroits prévus à cet effet; </p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate;</p></li>
                  </ul>
                </div>

                <h4>Adresse:</h4>
                <p>212 rue Sainte-Anne</p>

                <h4>Accès:</h4>
                <p>À votre arrivée, veuillez vous diriger au 212 rue Sainte-Anne pour aller y stationner votre véhicule. Le camping se situe à proximité, à côté du Pavillon Saint-Laurent (suivez les indications). Par la suite, nous recommandons de vous déplacer à pied vers le festival, via le raccourci du Chemin des Petites Franciscaines (15 minutes à pied).</p>

                <h4>Stationnement:</h4>
                <p>Le stationnement pour vos véhicules sera adjacent au site de camping, accessible par la rue Sainte-Anne (suivez les indications).</p>
              </div>

              <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
                <p className="mt-6"><BigButton href={getTicketURL()} text="RÉSERVER"/></p>
              </div>
            </div>

            <div className="w-full px-auto mx-auto">
              <h2>Merci à nos partenaires d'hébergement</h2>
              <div className="flex">
              <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.germainhotels.com/fr/hotel-et-spa-le-germain/charlevoix">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/legermain.png" />
                  </a>
              </p>
              <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://groupepronature.ca/">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/pronature.png" />
                  </a>
                </p>
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://hotelbaiestpaul.com">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/hotel_bsp.png" />
                  </a>
                </p>
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://moteldescascades.com/">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/mc.png" />
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </Wolflayout>
  )
}

export default Hebergement
